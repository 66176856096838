import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: () =>
        import ('../views/login.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
        import ('../views/login.vue')
    },
    {
        path: '/dashboard-admin',
        name: 'dashboard',
        component: () =>
        import ('../views/dashboard-admin.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
        import ('../views/dashboard.vue')
    },
    {
        path: '/employees',
        name: 'employees',
        component: () =>
        import ('../views/employees.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () =>
        import ('../views/404.vue')
    },
    {
        path: '/activities',
        name: 'activities',
        component: () =>
        import ('../views/activities.vue')
    },{
        path: '/admin-users',
        name: 'admin-users',
        component: () =>
        import ('../views/admin-users.vue')
    },
    {
        path: '/bulk',
        name: 'bulk',
        component: () =>
        import ('../views/bulk.vue')
    },
    {
        path: '/attendence-admin',
        name: 'attendence-admin',
        component: () =>
        import ('../views/attendence-admin.vue')
    },{
        path: '/attendence-employee',
        name: 'attendence-employee',
        component: () =>
        import ('../views/attendence-employee.vue')
    },
    {
        path: '/budget-expenses',
        name: 'budget-expenses',
        component: () =>
        import ('../views/budget-expenses.vue')
    },
    {
        path: '/budgets',
        name: 'budgets',
        component: () =>
        import ('../views/budgets.vue')
    },
    {
        path: '/catagories',
        name: 'catagories',
        component: () =>
        import ('../views/catagories.vue')
    },
    {
        path: '/client-detail',
        name: 'client-detail',
        component: () =>
        import ('../views/client-detail.vue')
    },
    {
        path: '/clients',
        name: 'clients',
        component: () =>
        import ('../views/clients.vue')
    },
    {
        path: '/dashboard-admin',
        name: '/dashboard-admin',
        component: () =>
        import ('../views//dashboard-admin.vue')
    },
    {
        path: '/departments',
        name: 'departments',
        component: () =>
        import ('../views/departments.vue')
    },
    {
        path: '/designations',
        name: 'designations',
        component: () =>
        import ('../views/designations.vue')
    },
    {
        path: '/employee-detail',
        name: 'employees',
        component: () =>
        import ('../views/employee-detail.vue')
    },
    {
        path: '/estimates',
        name: 'estimates',
        component: () =>
        import ('../views/estimates.vue')
    },
    {
        path: '/expenses',
        name: 'expenses',
        component: () =>
        import ('../views/expenses.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () =>
        import ('../views/forgot-password.vue')
    }, {
        path: '/holiday',
        name: 'holiday',
        component: () =>
        import ('../views/holiday.vue')
    }, {
        path: '/invoices',
        name: 'invoices',
        component: () =>
        import ('../views/invoices.vue')
    }, {
        path: '/leads',
        name: 'leads',
        component: () =>
        import ('../views/leads.vue')
    }, {
        path: '/leave-admin',
        name: 'leave-admin',
        component: () =>
        import ('../views/leave-admin.vue')
    }, {
        path: '/leave-employee',
        name: 'leave-employee',
        component: () =>
        import ('../views/leave-employee.vue')
    },{
        path: '/leave-settings',
        name: 'leave-settings',
        component: () =>
        import ('../views/leave-settings.vue')
    },{
        path: '/lock',
        name: 'lock',
        component: () =>
        import ('../views/lock.vue')
    },
    {
        path: '/applications',
        name: 'applications',
        component: () =>
        import ('../views/applications.vue')
    },
    {
        path: '/approvals',
        name: 'approvals',
        component: () =>
        import ('../views/approvals.vue')
    },{
        path: '/otp',
        name: 'otp',
        component: () =>
        import ('../views/leave-employee.vue')
    },{
        path: '/overtime',
        name: 'overtime',
        component: () =>
        import ('../views/overtime.vue')
    },{
        path: '/payslip',
        name: 'payslip',
        component: () =>
        import ('../views/pay-slip.vue')
    },{
        path: '/payments',
        name: 'payments',
        component: () =>
        import ('../views/payments.vue')
    },{
        path: '/payroll',
        name: 'payroll',
        component: () =>
        import ('../views/payroll.vue')
    },{
        path: '/policies',
        name: 'policies',
        component: () =>
        import ('../views/policies.vue')
    },{
        path: '/projects',
        name: 'projects',
        component: () =>
        import ('../views/projects.vue')
    },{
        path: '/provident-fund',
        name: 'provident-fund',
        component: () =>
        import ('../views/provident-fund.vue')
    },{
        path: '/register',
        name: 'register',
        component: () =>
        import ('../views/register.vue')
    },{
        path: '/resignation',
        name: 'resignation',
        component: () =>
        import ('../views/resignation.vue')
    },{
        path: '/salary',
        name: 'salary',
        component: () =>
        import ('../views/salary.vue')
    },{
        path: '/settings',
        name: 'settings',
        component: () =>
        import ('../views/settings.vue')
    },{
        path: '/shifts',
        name: 'shifts',
        component: () =>
        import ('../views/shifts.vue')
    },{
        path: '/task-board',
        name: 'task-board',
        component: () =>
        import ('../views/task-board.vue')
    },{
        path: '/tasks',
        name: 'tasks',
        component: () =>
        import ('../views/tasks.vue')
    },{
        path: '/taxes',
        name: 'taxes',
        component: () =>
        import ('../views/taxes.vue')
    },{
        path: '/termination',
        name: 'termination',
        component: () =>
        import ('../views/termination.vue')
    },{
        path: '/tickets',
        name: 'tickets',
        component: () =>
        import ('../views/tickets.vue')
    },{
        path: '/timesheet',
        name: 'timesheet',
        component: () =>
        import ('../views/timesheet.vue')
    }

]

const router = new VueRouter({
    routes,
    // mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

export default router