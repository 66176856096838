<template>
<div class="sidebar" id="sidebar">
    <div class="sidebar-left slimscroll hide">
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a @click="current = 'dashboard'"  class="nav-link" title="Dashboard" id="v-pills-dashboard-tab" data-bs-toggle="pill" href="#v-pills-dashboard" role="tab" aria-controls="v-pills-dashboard" aria-selected="true">
                <span class="material-icons-outlined">
                    home
                </span>
            </a>
            <a @click="current = 'employees'"  class="nav-link" id="v-pills-apps-tab" title="Employees" data-bs-toggle="pill" href="#v-pills-apps" role="tab" aria-controls="v-pills-apps" aria-selected="false">
                <span class="material-icons-outlined">
                    dashboard
                </span>
            </a>
            <a @click="current = 'clients'"  class="nav-link" id="v-pills-clients-tab" title="Clients" data-bs-toggle="pill" href="#v-pills-clients" role="tab" aria-controls="v-pills-clients" aria-selected="false">
                <span class="material-icons-outlined">
                    person
                </span>
            </a>
            <a @click="current = 'projects'"  class="nav-link" id="v-pills-projects-tab" title="Projects" data-bs-toggle="pill" href="#v-pills-projects" role="tab" aria-controls="v-pills-projects" aria-selected="false">
                <span class="material-icons-outlined">
                    topic
                </span>
            </a>
            <a @click="current = 'leads'" class="nav-link" id="v-pills-leads-tab" title="Leads" data-bs-toggle="pill" href="#v-pills-leads" role="tab" aria-controls="v-pills-leads" aria-selected="false">
                <span class="material-icons-outlined">
                    leaderboard
                </span>
            </a>
            <a @click="current = 'tickets'" class="nav-link" id="v-pills-tickets-tab" title="Tickets" data-bs-toggle="pill" href="#v-pills-tickets" role="tab" aria-controls="v-pills-tickets" aria-selected="false">
                <span class="material-icons-outlined">
                    confirmation_number
                </span>
            </a>
            <a @click="current = 'sales'"  class="nav-link" id="v-pills-sales-tab" title="Sales" data-bs-toggle="pill" href="#v-pills-sales" role="tab" aria-controls="v-pills-sales" aria-selected="false">
                <span class="material-icons-outlined">
                    shopping_bag
                </span>
            </a>
            <a class="nav-link" id="v-pills-accounting-tab" title="Accounting" data-bs-toggle="pill" href="#v-pills-accounting" role="tab" aria-controls="v-pills-accounting" aria-selected="false">
                <span class="material-icons-outlined">
                    account_balance_wallet
                </span>
            </a>
            <a  class="nav-link" id="v-pills-payroll-tab" title="Payroll" data-bs-toggle="pill" href="#v-pills-payroll" role="tab" aria-controls="v-pills-payroll" aria-selected="false">
                <span class="material-icons-outlined">
                    request_quote
                </span>
            </a>

            <a  v-bind:class="{'active':current == 'policies'}" class="nav-link" id="v-pills-policies-tab" title="Policies" data-bs-toggle="pill" href="#v-pills-policies" role="tab" aria-controls="v-pills-policies" aria-selected="false">
                <span class="material-icons-outlined">
                    verified_user
                </span>
            </a>

            <a @click="current = 'resignation'" v-bind:class="{'active':current == 'resignation'}" class="nav-link" id="v-pills-resignation-tab" title="Resignation" data-bs-toggle="pill" href="#v-pills-resignation" role="tab" aria-controls="v-pills-resignation" aria-selected="false">
                <span class="material-icons-outlined">
                    do_not_disturb_alt
                </span>
            </a>
            <a @click="current = 'termination'" v-bind:class="{'active':current == 'termination'}" class="nav-link" id="v-pills-termination-tab" title="Termination" data-bs-toggle="pill" href="#v-pills-termination" role="tab" aria-controls="v-pills-termination" aria-selected="false">
                <span class="material-icons-outlined">
                    indeterminate_check_box
                </span>
            </a>

            <a @click="current = 'admin-users'" v-bind:class="{'active':current == 'admin-users'}" class="nav-link" id="v-pills-users-tab" title="Users" data-bs-toggle="pill" href="#v-pills-users" role="tab" aria-controls="v-pills-users" aria-selected="false">
                <span class="material-icons-outlined">
                    group_add
                </span>
            </a>
            <a @click="current = 'settings'" v-bind:class="{'active':current == 'settings'}" class="nav-link" id="v-pills-settings-tab" title="Settings" data-bs-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                <span class="material-icons-outlined">
                    settings
                </span>
            </a>

        </div>
    </div>
    <div class="sidebar-right" @click="rmClass">
        <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade" v-bind:class="{' show': current == 'dashboard',' active':current == 'dashboard'}" id="v-pills-dashboard" role="tabpanel" aria-labelledby="v-pills-dashboard-tab">
                <p>MENU</p>
               
                <ul class="sideMenu">
                    <li @click="rmClass" v-bind:class="{'active':currentURL.path == '/dashboard'}">
                        <router-link :to="{path:'/dashboard'}">
                            <img class="sm-icon" src="@/assets/icons/dashboard.png">
                            Dashboard</router-link>
                    </li>

                    <li @click="rmClass" v-if="user.role == 'HR' || user.role == 'SuperAdmin' " v-bind:class="{'active':currentURL.path == '/employees'}">
                        <router-link :to="{path:'/employees'}">
                            <img class="sm-icon" src="@/assets/icons/employee.png">
                            Employees </router-link>
                    </li>

                    <li @click="rmClass" v-bind:class="{'active':currentURL.path == '/leave-employee'}">
                        <router-link :to="{path:'/leave-employee'}">
                            <img class="sm-icon" src="@/assets/icons/sunbed.png">
                            Leaves</router-link>
                    </li>
                     <li @click="rmClass" v-bind:class="{'active':currentURL.path == '/applications'}">
                        <router-link :to="{path:'/applications'}">
                            <img class="sm-icon" src="@/assets/icons/resume.png">
                             Applications</router-link>
                    </li>
                     <li @click="rmClass" v-bind:class="{'active':currentURL.path == '/approvals'}">
                        <router-link :to="{path:'/approvals'}">
                            <img class="sm-icon" src="@/assets/icons/mobile-phone.png">
                             Approvals</router-link>
                    </li>
                    <li @click="rmClass" v-bind:class="{'active':currentURL.path == '/attendence-employee'}">
                        <router-link :to="{path:'/attendence-employee'}">
                            <img class="sm-icon" src="@/assets/icons/immigration.png">
                            Attendance</router-link>
                    </li>
                    <li @click="rmClass" v-if="user.role == 'HR' || user.role == 'SuperAdmin' " v-bind:class="{'active':currentURL.path == '/departments'}">
                        <router-link :to="{path:'/departments'}">
                            <img class="sm-icon" src="@/assets/icons/networking.png">
                            Departments</router-link>
                    </li>
                    <li @click="rmClass" v-if="user.role == 'HR' || user.role == 'SuperAdmin' " v-bind:class="{'active':currentURL.path == '/designations'}">
                        <router-link :to="{path:'/designations'}">
                            <img class="sm-icon" src="@/assets/icons/graphic-designer.png">
                            Designations</router-link>
                    </li>
                    <li @click="rmClass" v-if="user.role == 'HR' || user.role == 'SuperAdmin' " v-bind:class="{'active':currentURL.path == '/settings'}">
                        <router-link :to="{path:'/settings'}">
                            <img class="sm-icon" src="@/assets/icons/gear.png">
                            Settings</router-link>
                    </li>
                     <li @click="rmClass" v-if="user.role == 'HR' || user.role == 'SuperAdmin' " v-bind:class="{'active':currentURL.path == '/settings'}">
                        <router-link :to="{path:'/bulk'}">
                            <img class="sm-icon" src="@/assets/icons/wallet.png">
                            Bulk Actions</router-link>
                    </li>
                    <!-- <li @click="rmClass" v-bind:class="{'active':currentURL.path == '/activities'}">
                        <router-link :to="{path:'/activities'}">
                            <img class="sm-icon" src="@/assets/icons/notification.png">
                            Notifications</router-link>
                    </li> -->

                </ul>
            </div>
           
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    components: {},
    data() {
        return {
            current: "dashboard",
            currentURL: false,
            user:JSON.parse(localStorage.getItem('user'))
        }
    },
    watch: {
        "$route.params": {
        handler(newValue, preValue) {
             this.currentURL = this.$route;
        },
        immediate: true
        }
    },
    created() {
        this.currentURL = this.$route;
    },
    methods: {
        rmClass(){
            document.getElementById('sidebar').classList.remove('showSideBar')
        }
    }
};
</script>
